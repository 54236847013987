import _ from 'lodash'

export default {
  data() {
    return {
      page: +this.$route.query.page || 1,
      pageSize: 50,
      pageCount: 0,
      items: [],
      callbackFn: undefined
    }
  },
  methods: {
    pageChangeHandler(page) {
      this.$router.push(`${this.$route.path}?page=${page}`)

      if (this.callbackFn !== undefined) {
        this.callbackFn(page)
      } else {
        // INFO: Старая механика без пэйджинга на бэке
        this.items = this.allItems[page - 1] || this.allItems[0]
      }
    },
    setupPagination(callbackFn, page, pageSize, items, count) {
      this.callbackFn = callbackFn
      this.page = page
      this.pageSize = pageSize
      this.pageCount = Math.ceil(count/this.pageSize)
      this.items = items
    }
  }
}
